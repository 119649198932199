// Referring to https://www.gatsbyjs.org/blog/2017-07-19-creating-a-blog-with-gatsby/

import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "gatsby-image"
import IconWatch from "../icons/icon-watch"
import IconCalendar from "../icons/icon-calendar"
import IconArrow from "../icons/icon-arrow"
import IconLightbulb from "../icons/icon-lightbulb"
import FacebookFollow from "../components/facebook-follow"


export default function BlogPost({
  data, pageContext // this prop will be injected by the GraphQL query we'll write in a bit
}) {
  const {prev, next} = pageContext
  const { markdownRemark: post } = data // data.markdownRemark holds your post data
  
  const imagePath = post.frontmatter || post.frontmatter.childImageSharp.fluid;

  return (
    <Layout>
      <SEO title={`${post.frontmatter.title} - The Web Design Blog for Business Owners`} description={post.frontmatter.description} image={imagePath}/>
      <div className="blog-post-header">
        <h3 className="h3-on-h1">Article</h3>
        <h1>{post.frontmatter.title}</h1>
        <ul className="has-inline-svg">
          <li>
            <IconWatch/>
            <p>{post.timeToRead} min. read</p>
          </li>
          <li>
            <IconCalendar/>
            <p>{post.frontmatter.date}</p>
          </li>
        </ul>
        {post.frontmatter.cover && (
        <Image
          fluid={post.frontmatter.cover.childImageSharp.fluid}
          alt="Banner Image"
        />
          )}
        
        <div id="blog-description">
          <ul class="has-inline-svg"><li><IconLightbulb/><h3>You are about to...</h3></li></ul>
          <p>{post.frontmatter.description}</p>
        </div>
      </div>
      <div className="blog-post-content section" dangerouslySetInnerHTML={{ __html: post.html }}/>
      <FacebookFollow/>
      {/* <div className="blog-post-content">
        <h2>Over to you</h2>
        <p>What do you think about this article? Do you have any questions or interesting bits to share? <strong>Leave a comment below.</strong></p>
      </div>
      <div id="blog-post-comment">
      </div> */}
      <ul className="blog-prevnext sub-cols-1-1 section">
        {prev && <li>
          <Link to={prev.frontmatter.path}>
            <div className="blog-prevnext-label">
              <IconArrow/>
              <p>Previous Post</p>
            </div>
            <h3>{prev.frontmatter.title}</h3>
          </Link>
        </li>}
        {next && <li id="blog-prevnext-next">
          <Link to={next.frontmatter.path}>
            <div className="blog-prevnext-label">
              <p>Next Post</p>
              <IconArrow/>
            </div>
            <h3>{next.frontmatter.title}</h3>
          </Link>
      </li>}
      </ul>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      timeToRead
      frontmatter {
        date(formatString: "DD MMMM YYYY")
        path
        title
        description
        cover {
          childImageSharp {
            fluid (maxWidth: 1024, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`